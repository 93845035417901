import React, { Component } from "react"
import HomepageCategories from "../components/HomepageCategories"
import Header from "../components/header"
import { HeaderFunc } from "../scrollFunctions/headerFunc"
import locomotiveScroll from "locomotive-scroll"
import IconWrap from "../components/IconWrap"
import CurrentPage from "../components/CurrentPageIdentifier"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"

export default class Homepage extends Component {
  constructor() {
    super()
    this.state = { scroll: {} }
    this.scrollRef = React.createRef()
  }

  componentDidMount() {
    const scroll = new locomotiveScroll({
      el: this.scrollRef.current,
      smooth: true,
      smoothMobile: true,
      smartphone: {
        smooth: true,
      },
      tablet: {
        smooth: true,
      },
      // offset: ["30%",0]
    })

    scroll.on("call", func => {
      if (func === "headerFunc") {
        HeaderFunc()
      }
    })

    this.setState({ scroll })
  }

  componentDidUpdate() {
    this.state.scroll.update()
    // this.state.scroll.update()
    // this.state.scroll.start()
  }

  componentWillUnmount() {}

  render() {
    const { data } = this.props

    console.log(this.props.data)

    return (
      <div>
        <Helmet>
          <title>David Lindsay</title>
          <meta
            name="description"
            content="Commercial Photographer | Tadcaster | Yorkshire | @photosbydavid "
          />
        </Helmet>
        <IconWrap />
        <CurrentPage
          title={
            this.props.data.page.title === "Home"
              ? "David Lindsay"
              : this.props.data.page.title
          }
        />
        <div ref={this.scrollRef}>
          <Header
            className="header"
            state={this.state}
            headerText={
              this.props.data.page.title === "Home"
                ? "David Lindsay"
                : this.props.data.page.title
            }
          />
          <HomepageCategories HomepageCategories={data.page.acf_home} />
        </div>
      </div>
    )
  }
}

export const query = graphql`
  query HomepageQuery {
    page: wpPage(title: { eq: "Home" }) {
      id
      title
      content

      acf_home {
        homepageCategorySelector {
          category {
            ... on WpPage {
              id
              title
              slug
              link
            }
          }
          image1 {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          image2 {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
